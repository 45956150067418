import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import StaticSection from "../components/StaticSection"
import Contact from "../components/Contact"

const Franczyza = () => {
  const { imageMobile, imagePc } = useStaticQuery(graphql`
    query {
      imageMobile: file(relativePath: { eq: "franczyzaMobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imagePc: file(relativePath: { eq: "FranczyzaPC.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <StaticSection
        title="Lorem"
        desc=" Lorem ipsum dolor sit, amet consectetur adipisicing elit.
        Consequatur quidem impedit"
        img={imageMobile}
        imgPc={imagePc}
        buttonText="Zadzwoń"
        link="tel:883882500"
        sectionTitle="ipsum dolor sit."
        sectionDesc={
          <>
            <p className="section-desc">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Consequatur quidem impedit repellendus iusto illum blanditiis
              omnis. Itaque tempore impedit ea mollitia. Enim porro fugit
              corporis, aliquid debitis non itaque rem, ducimus placeat at odio
              pariatur ipsum laboriosam id reiciendis. Hic, consectetur dolor?
              Eaque qui porro cum error atque vero, corporis, mollitia ab cumque
              alias hic ea enim laboriosam molestiae delectus?
            </p>
            <p className="section-desc">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Culpa
              magnam aliquid, sit velit suscipit sint accusantium dolorem autem
              cumque est adipisci ipsum perspiciatis labore itaque delectus,
              nobis voluptatem ipsam vitae recusandae aliquam aspernatur
              consequatur. Voluptates non sed eum totam distinctio.
            </p>
            <p className="section-desc" style={{ fontWeight: 700 }}>
              Lorem ipsum:
            </p>
            <ul>
              <li>Lorem ipsum</li>
              <li>Lorem ipsum dolor sit amet</li>
            </ul>
          </>
        }
        listTitle="Lorem ipsum:"
        listItems={[
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>,
          <p>
            Lorem ipsum dolor sit <span>amet consectetur</span>, adipisicing
            elit.
          </p>,
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>,
          <p>
            Lorem ipsum dolor sit amet consectetur,{" "}
            <span>adipisicing elit</span>.
          </p>,
        ]}
        listButton="Zadzwoń"
        listButtonHref="tel:883882500"
      />
      <Contact />
    </Layout>
  )
}

export default Franczyza
